import styled from 'styled-components';
import Colours from '../../constants/colours';
import Typography from '../../constants/typography';
import {DisplayMessageProps, DisplayMessageTypes} from './DisplayMessage';

export const Wrapper = styled.div`
  background-color: ${(p: DisplayMessageProps) =>
    p.type === DisplayMessageTypes.SUCCESS
      ? Colours.green
      : p.type === DisplayMessageTypes.WARNING
      ? Colours.yellow
      : Colours.red};
  color: ${Colours.white};
  font-size: ${Typography.body.desktop.fontSize};
  line-height: 1em;
  padding: 1em;
  text-align: center;

  & > div {
    transform: translateY(2px);
  }
`;
