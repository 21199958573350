import React, {useState, useEffect} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import {PageProps} from '../types';
import HeaderImage from '../components/HeaderImage';
import {Layout} from '../components/Layout';
import PageWidth from '../components/PageWidth';
import {getImageFromData, getPageData} from '../utils';
import SEO from '../components/SEO';
import {PageWrapper} from '../styles/page';
import {PrivacyPolicyQuery} from './__generated__/PrivacyPolicyQuery';
import {
  DisplayMessage,
  DisplayMessageTypes,
} from '../components/DisplayMessage';
import Typography from '../constants/typography';

type PrivacyPolicyProps = PageProps<PrivacyPolicyQuery>;

const PrivacyPolicy = (props: PrivacyPolicyProps) => {
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [unableToLoad, setUnableToLoad] = useState(false);

  const data = getPageData(props.data);
  const headerImage = getImageFromData(data, 'headerImage');

  useEffect(() => {
    async function fetchPrivacyPolicy() {
      try {
        const response = await fetch(
          'https://www.iubenda.com/api/privacy-policy/29650626/only-legal'
        );

        const body = await response.json();

        setPrivacyPolicy(body.content);
      } catch (error) {
        console.error(error);
        setUnableToLoad(true);
      }
    }

    fetchPrivacyPolicy();
  }, []);

  return (
    <Layout>
      <SEO
        title={data.pageTitle}
        keywords={data.metaKeywords.split(',')}
        description={data.metaDescription}
      />
      <HeaderImage src={headerImage} />
      <PageWrapper>
        <PageWidth>
          {unableToLoad ? (
            <DisplayMessage type={DisplayMessageTypes.ERROR}>
              <p>
                There was a problem loading this page, please refresh to try
                again.
              </p>
            </DisplayMessage>
          ) : (
            <IUBenderStyling
              dangerouslySetInnerHTML={{__html: privacyPolicy}}
            />
          )}
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

const IUBenderStyling = styled.div`
  font-size: ${Typography.legal.fontSize};
  line-height: ${Typography.legal.lineHeight};

  h1 {
    font-size: ${Typography.heading.legal.fontSize};
    line-height: ${Typography.heading.legal.lineHeight};
  }

  a {
    font-size: ${Typography.legal.fontSize};
  }

  h2 {
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-top: 2rem;
    border-top: 1px dotted #ddd;
  }

  h3 {
    margin-top: 1rem;
  }

  ul {
    margin-left: 2rem;
  }
`;

export const pageQuery = graphql`
  query PrivacyPolicyQuery {
    en: allContentfulAmpifyWebPrivacyPolicy(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          pageTitle
          metaDescription
          metaKeywords
          headerImage {
            ...AmpifyHeaderImage
          }
        }
      }
    }
  }
`;

export default PrivacyPolicy;
